<template v-if="!isLoading">
  <div class="kb-form-row" v-for="(param, idx) in params" :key="param.boardCstmItmSn">

    <span class="text-muted fs-8">{{param.itemNm}}</span>

    <input v-if="param.itemType == 'number'" type="text" class="kb-form-control" v-model="param.value" :placeholder="param.itemNm" :readonly="isReadOnly" maxlength="50" @keyup="numbersOnly" @keydown="numbersOnly" @focusout="numbersOnly" />
    <input v-else-if="param.itemType == 'cardCert'" type="text" class="kb-form-control" v-model="param.value" :placeholder="param.itemNm" :readonly="isReadOnly" maxlength="19" @keyup="cardCertFilter(idx)" @focusout="cardCertFilter(idx)" />
    <KbDatepicker v-else-if="param.itemType == 'date'" v-model="param.value" date-format="yyyy-MM-dd" :placeholder="param.itemNm" :readonly="isReadOnly" />
    <div v-else-if="param.itemType == 'money'">
      <input type="text" class="kb-form-control" style="width: calc(100% - 1.5rem);"
             :class="{'text-end': param.value}" v-model="param.value" :placeholder="param.itemNm" :readonly="isReadOnly" maxlength="50" @keyup="moneyFilter(idx)" @focusout="moneyFilter(idx)" />
      <span class="text-muted align-baseline" style="width: 1.5rem">원</span>
    </div>
    <div v-else-if="param.itemType == 'ox'">
      <div class="kb-form-check kb-form-check-inline">
        <input type="radio" :name="`radio_${param.itemType}_${param.boardCstmItmSn}`" :id="`radio_${param.itemType}_${param.boardCstmItmSn}_1`" class="kb-form-check-input" v-model="param.value" value="O" :disabled="isReadOnly"/>
        <label :for="`radio_${param.itemType}_${param.boardCstmItmSn}_1`" class="kb-form-check-label"><span class="text">O</span></label>
      </div>
      <div class="kb-form-check kb-form-check-inline">
        <input type="radio" :name="`radio_${param.itemType}_${param.boardCstmItmSn}`" :id="`radio_${param.itemType}_${param.boardCstmItmSn}_2`" class="kb-form-check-input" v-model="param.value" value="X" :disabled="isReadOnly" />
        <label :for="`radio_${param.itemType}_${param.boardCstmItmSn}_2`" class="kb-form-check-label"><span class="text">X</span></label>
      </div>
    </div>
    <div v-else-if="param.itemType == 'cttTy'">
      <div class="kb-form-check kb-form-check-inline">
        <input type="radio" :name="`radio_${param.itemType}_${param.boardCstmItmSn}`" :id="`radio_${param.itemType}_${param.boardCstmItmSn}_1`" class="kb-form-check-input" v-model="param.value" value="PPT+녹음" :disabled="isReadOnly"/>
        <label :for="`radio_${param.itemType}_${param.boardCstmItmSn}_1`" class="kb-form-check-label"><span class="text">PPT+녹음</span></label>
      </div>
      <div class="kb-form-check kb-form-check-inline">
        <input type="radio" :name="`radio_${param.itemType}_${param.boardCstmItmSn}`" :id="`radio_${param.itemType}_${param.boardCstmItmSn}_2`" class="kb-form-check-input" v-model="param.value" value="PPT+강사" :disabled="isReadOnly" />
        <label :for="`radio_${param.itemType}_${param.boardCstmItmSn}_2`" class="kb-form-check-label"><span class="text">PPT+강사</span></label>
      </div>
      <div class="kb-form-check kb-form-check-inline">
        <input type="radio" :name="`radio_${param.itemType}_${param.boardCstmItmSn}`" :id="`radio_${param.itemType}_${param.boardCstmItmSn}_3`" class="kb-form-check-input" v-model="param.value" value="PIP" :disabled="isReadOnly" />
        <label :for="`radio_${param.itemType}_${param.boardCstmItmSn}_3`" class="kb-form-check-label"><span class="text">PIP</span></label>
      </div>
      <div class="kb-form-check kb-form-check-inline">
        <input type="radio" :name="`radio_${param.itemType}_${param.boardCstmItmSn}`" :id="`radio_${param.itemType}_${param.boardCstmItmSn}_4`" class="kb-form-check-input" v-model="param.value" value="기타" :disabled="isReadOnly" />
        <label :for="`radio_${param.itemType}_${param.boardCstmItmSn}_4`" class="kb-form-check-label"><span class="text">기타</span></label>
      </div>
    </div>
    <div v-else-if="param.itemType == 'crseDistEval'">
      <select v-model="param.value" class="kb-form-control kb-form-select">
        <option v-for="(data, idx) in crseDistList" :key="idx">{{data.crseNm}} - 차수코드 {{data.distCrseSn}}</option>
      </select>
    </div>
    <div v-else-if="param.itemType == 'evlCnclRsn'">
      <select v-model="param.value" class="kb-form-control kb-form-select">
        <option v-for="(data, idx) in crseCnclRsnList" :key="idx">{{data.cdNm}}</option>
      </select>
    </div>
    <input v-else type="text" class="kb-form-control" v-model="param.value" :placeholder="param.itemNm" :readonly="isReadOnly" maxlength="50" />
  </div>
</template>

<script>
import {onMounted, computed, reactive, ref} from "vue";
import {useStore} from "vuex";
import {numbersOnly, numberComma, getItems, lengthCheck, timestampToDateFormat, getTodayDate} from "@/assets/js/util";
import {ACT_GET_CRSE_REG_APLY_LIST} from "@/store/modules/course/course";
import {getDcdOptions} from "@/assets/js/ui.init";
import KbDatepicker from "@/components/common/KbDatepicker";

export default {
  name: 'BoardCustomInfo',
  components: {KbDatepicker},
  props: {
    customInfos: {
      type: Array,
      required: true,
    },
    isReadOnly: Boolean,
  },
  setup(props) {
    const store = useStore();
    const params = reactive(props.customInfos);
    const isLoading = ref(true);
    const crseDistList = ref([]);
    const crseCnclRsnList = computed(() => getDcdOptions('2128'));

    const moneyFilter = (idx) => {
      params[idx].value = numberComma(params[idx].value);
    }

    const cardCertFilter = (idx) => {
      let dataValue = params[idx].value.replace(/[^0-9]/g, '');
      if(dataValue){
        params[idx].value = dataValue.match(/.{1,4}/g).join('-');
      }else{
        params[idx].value = dataValue;
      }
    }

    onMounted(() => {
      // 입과 차수 정보 불러오기
      store.dispatch(`course/${ACT_GET_CRSE_REG_APLY_LIST}`, {}).then((res) => {
        if(lengthCheck(res)){
          getItems(res).filter(data => {
            // 연수 시작일 이후 & 평가일 존재 & 특별차수 제외
            if(
                timestampToDateFormat(data.bgngDt, 'yyyy.MM.dd') <= getTodayDate('yyyy.mm.dd')
                && (data.aprvDmndDt != null && data.aprvDmndDt != '')
                && (data.splCrseTyCdDcd == null || data.splCrseTyCdDcd == '')
            ){
              crseDistList.value.push(data);
            }
          });
        }else{
          crseDistList.value = [];
        }
      }).finally(() => {
        isLoading.value = false;
      })
    })

    return {
      params,
      crseDistList,
      crseCnclRsnList,
      isLoading,
      numbersOnly,
      moneyFilter,
      cardCertFilter,
    }
  }
}
</script>

<style>
.date-placeholder::before {
  content: attr(placeholder);
  width: 100%;
  height: 100%;
}
.date-placeholder:focus::before,
.date-placeholder:valid::before {
  display: none;
}
</style>