<template>
  <div id="calendar-picker-monthly" :class="[isOpen ? 'is-active' : 'is-inactive']" class="kb-form-period-date dropdown" data-id="dropdown-calendar-picker">
    <button class="kb-btn kb-btn-outline rounded-lg dropdown-btn" @click="toggleCalender">
      <span v-if="modelValue" class="text">{{modelValue}}</span>
      <template v-else><span class="text text-muted">{{placeholder}}</span><i class="icon-calender"></i></template>
    </button>
    <div class="dropdown-target">
      <div class="dropdown-box">
        <!-- calendar-picker-container -->
        <div class="calendar-picker-container">
          <!-- calendar-picker-container > calendar-picker -->
          <div class="calendar-picker">
            <div class="picker-body">
              <div class="datepicker-header">
                <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev" @click="changeMonth(false)"><i class="icon-prev"></i></button>
                <div class="title">{{ currentYearMonth }}</div>
                <button class="kb-btn-datepicker-nav kb-btn-datepicker-next" @click="changeMonth(true)"><i class="icon-next"></i></button>
              </div>
              <div class="datepicker-table datepicker-table-week">
                <table>
                  <thead>
                  <tr>
                    <th>일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th>토</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(week, idx) in currentWeeks" :key="idx">
                      <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                        <template v-if="day.month === currentMonth">
                          <button class="kb-btn-day" :class="{ 'is-active' : startDt.ymd === day.ymd }" @click="handleSelectDate(day)">
                            <span class="text">{{ day.day }}</span></button>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- //calendar-picker-container -->
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import {dateToDateFormat, getCalendar, setParams, ymdStrToDate, ymdStrToDateFormat} from '@/assets/js/util';

export default {
  name: 'KbDatepicker',
  props: {
    modelValue: String,
    dateFormat: {
      type: String,
      default: () => {return 'yyyy.MM.dd'}
    },
    placeholder: {
      type: String,
      default: () => {return '날짜 선택'}
    },
    readonly : {
      type: Boolean,
      default: () => {return false}
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const currentDate = new Date();
    // 현재 연월일 데이트 피커 데이터
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);

    // 이번주 데이터
    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value));

    const isOpen = ref(false);

    const startDt = reactive({
      year: '',
      month: '',
      day: '',
      ymd: '',
      ymdformat: ''
    });

    const currentYearMonth = computed(() => {
        return `${currentYear.value}년 ${currentMonth.value}월`;
    });

    const toggleCalender = () => {
      if(props.readonly){
        isOpen.value = false;
      }else{
        isOpen.value = !isOpen.value;
      }
    }

    // 다음, 이전 연 월 버튼 기능
    const changeMonth = (isNext) => {
      if (isNext) {
        if (currentMonth.value == 12) {
          currentYear.value++;
          currentMonth.value = 1;
        } else {
          currentMonth.value++;
        }
      } else {
        if (currentMonth.value == 1) {
          currentYear.value--;
          currentMonth.value = 12;
        } else {
          currentMonth.value--;
        }
      }
    };

    const init = () => {
      if(props.modelValue){
        let selected = new Date(props.modelValue);
        currentYear.value = selected.getFullYear();
        currentMonth.value = selected.getMonth() + 1;
        startDt.ymd = dateToDateFormat(selected,'yyyyMMdd');
      }else{
        currentYear.value = currentDate.getFullYear();
        currentMonth.value = currentDate.getMonth() + 1;
      }
    };

    const handleSelectDate = (date) => {
      setParams(startDt, date);
      isOpen.value = false;
    };

    const isPastDay = (date) => {
      return currentDate >= ymdStrToDate(date.ymd);
    };

    watch(() => isOpen.value, () => {
      // 열고 닫을때 오늘 기준 월 보여주기 위해 init()
      if (isOpen.value) {
        init();
      }
    });

    watch(() => startDt.ymd, () => {
      emit('update:modelValue', ymdStrToDateFormat(startDt.ymd, props.dateFormat));
    });

    return {
      isOpen,
      startDt,
      currentYear,
      currentMonth,
      currentWeeks,
      currentYearMonth,
      toggleCalender,
      changeMonth,
      handleSelectDate,
      isPastDay,
    };
  },
};
</script>
