<template>
  <template v-if="show">
    <div v-if="isMobile" class="board-view-container mb-6">
      <MobileBoardViewContent :board-mst="boardMst" :post="postTmp" :custom-infos="customInfos" :files="files" :download-atch="downloadAtch" />
    </div>
    <div v-else class="popup-container is-active">
      <div class="popup">
        <div class="board-view-container mb-6">
          <BoardViewContent :board-mst="boardMst" :post="postTmp" :custom-infos="customInfos" :files="files" :download-atch="downloadAtch" />
        </div>
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click="$emit('update:show', false)"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import {computed, onMounted, reactive, watch} from "vue";
import {useAlert} from "@/assets/js/modules/common/alert";
import BoardViewContent from "@/components/board/BoardViewContent.vue";
import MobileBoardViewContent from "@/components/board/mobile/BoardViewContent.vue";
import {useStore} from "vuex";
import {setParams} from "@/assets/js/util";

export default {
  name: 'BoardPreviewModal',
  components: {BoardViewContent, MobileBoardViewContent},
  props: {
    show: Boolean,
    boardMst: Object,
    post: Object,
    files: Array,
    customInfos: Array,
  },
  emits: ['update:show'],
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session)
    const isMobile = computed(() => store.state.auth.isMobile)
    const postTmp = reactive({
      comPostSn: 0,
      boardMstSn: 0,
      boardCateSn: 0,
      postGrpNo: 0,
      postLvl: 0,
      postAnsSeq: 0,
      postTitle: '',
      postCn: '',
      postFileAtchYn: 'N',
      userNickNm: '',
      thumbFileUrl: '',
      thumbFileNm: '',
      topFixYn: 'N',
      stt: '00',
      regDt: null,
      mdfcnDt: null,
      hashTag: '',
      inqCnt: null,
    });
    const {showMessage} = useAlert();

    const downloadAtch = () => {
      showMessage(`미리보기 화면에서는 <br>첨부파일을 다운로드할 수 없습니다.`);
    }

    const setPreviewParam = () => {
      setParams(postTmp, props.post);
      if(props.show){
        if(!postTmp.postTitle) postTmp.postTitle = '제목을 입력하세요';
        if(!postTmp.lrnerNm) postTmp.lrnerNm = session.value.lrnerNm;
        if(!postTmp.deptNm) postTmp.deptNm = session.value.deptNm;
        if(!postTmp.jbgdNm) postTmp.jbgdNm = session.value.jbgdNm;
        if(!postTmp.jbpsNm) postTmp.jbpsNm = session.value.jbpsNm;
        if(!postTmp.inqCnt) postTmp.inqCnt = 0;
        if(!postTmp.regDt){
          postTmp.regDt = new Date();
        }else if(!postTmp.mdfcnDt){
          postTmp.mdfcnDt = new Date();
        }
      }
    }

    watch(() => props.show, setPreviewParam)

    onMounted(setPreviewParam)

    return {
      isMobile,
      postTmp,
      downloadAtch,
    }
  }
}
</script>